@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&family=Inconsolata:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&family=Inconsolata:wght@200;300;400&family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

span {
    color:white;
    font-family: Share Tech Mono;
    font-size: 3.75rem/* 60px */;
    line-height: 1;
    margin-bottom: 2rem/* 32px */;
}

:root {
    color-scheme: dark;
}